<!--
 * @Description: 房间显示
 * @Date: 2022-03-16 17:40:28
 * @LastEditTime: 2022-03-29 16:13:06
-->
<template>
  <div class="rtc-container">
    <!-- 进房操作区域 -->
    <!-- <p v-if="isHostMode" class="label">{{ $t('Operation') }}</p> -->
    <!-- <div class="control-container">
      <div class="rtc-control-container">
        <el-button
          class="button"
          type="primary"
          size="small" :disabled="isJoining || isJoined" @click="handleJoinRoom">{{ $t('Join Room') }}</el-button>
        <el-button
          v-if="isHostMode"
          class="button"
          type="primary"
          size="small" :disabled="isPublishing || isPublished" @click="handlePublish">{{ $t('Publish') }}</el-button>
        <el-button
          v-if="isHostMode"
          class="button"
          type="primary" size="small" @click="handleUnpublish">{{ $t('Unpublish') }}</el-button>
        <el-button
          class="button"
          type="primary" size="small" @click="handleLeave">{{ $t('Leave Room') }}</el-button>
      </div>
      <div v-if="isHostMode" class="screen-share-control-container">
        <el-button
          class="button"
          type="primary"
          size="small"
          :disabled="isShareJoined && isSharePublished"
          @click="handleStartScreenShare">{{ $t('Start Screen Share') }}</el-button>
        <el-button
          class="button"
          type="primary" size="small" @click="handleStopScreenShare">{{ $t('Stop Screen Share') }}</el-button>
      </div>
    </div> -->

    <!-- 显示邀请链接 -->
    <!-- <div v-if="showInviteLink" class="invite-link-container">
      <span v-if="isEnLang">Copy the link to invite friends to join the video call, one link can invite only one person,
        the link will be updated automatically after copying.</span>
      <span v-else>复制链接邀请好友加入视频通话，一条链接仅可邀请一人，复制后自动更新链接。</span>
      <el-input class="invite-input" v-model="inviteLink">
        <template slot="prepend">
          <el-tooltip
            :visibleArrow="false"
            effect="dark"
            content="Copied!"
            placement="bottom"
            :manual="true"
            v-model="showCopiedTip">
            <span class="invite-btn" @click="handleCopyInviteLink">
              <svg-icon icon-name="copy"></svg-icon>
            </span>
          </el-tooltip>
        </template>
      </el-input>
    </div> -->
    <div v-if="isPad" class="top-bar">
      <div class="teacher"><img src="../assets/image/teacher.png" alt="">{{room_info.time_teacher}}</div>
      <div class="class-name">{{room_info.level+ room_info.class_name}}</div>
      <div class="btn-to" @click="onBack">返回</div>
    </div>
    <div class="videos" v-if="isPad">
      <div class="left">
        <!-- 老师分享视频 -->
        <div class="remote-container teacher-screen" v-if="teacherScreen">
          <div
            :key="(teacherScreen.getUserId()+'screen')"
            :id="(teacherScreen.getUserId()+'screen')"
            class="remote-stream-container">
          </div>
        </div>
        <!-- 老师主视频 -->
        <div class="remote-container teacher-video" :class="teacherScreen?'teacher-video-pos':''" v-if="teacherVideo">
          <div
            :key="teacherVideo.getUserId()+'live'"
            :id="teacherVideo.getUserId()+'live'"
            class="remote-stream-container">
          </div>
        </div>

      </div>
      <div class="right">
        <div class="info-container" :class="$isMobile && 'info-container-mobile'">
          <!-- 本地流区域 -->
          <div v-if="localStream" class="local-stream-container">
            <!-- 本地流播放区域 -->
            <div id="localStream" class="local-stream-content"></div>
            <!-- 本地流操作栏 -->
            <div v-if="isPlayingLocalStream" class="local-stream-control">
              <div class="video-control control">
                <span v-if="!isMutedVideo" @click="muteVideo">
                  <svg-icon icon-name="video" class="icon-class"></svg-icon>
                </span>
                <span v-if="isMutedVideo"  @click="unmuteVideo">
                  <svg-icon icon-name="video-muted" class="icon-class"></svg-icon>
                </span>
              </div>
              <div class="audio-control control">
                <span v-if="!isMutedAudio" @click="muteAudio">
                  <svg-icon icon-name="audio" class="icon-class"></svg-icon>
                </span>
                <span v-if="isMutedAudio" @click="unmuteAudio">
                  <svg-icon icon-name="audio-muted" class="icon-class"></svg-icon>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- 远端流区域 -->
        <div class="remote-container remain-container">
          <div
            v-for="(item) in remoteStreamList"
            :key="item.getUserId()"
            :id="item.getUserId()"
            class="remote-stream-container">
          </div>
        </div>
      </div>

    </div>
    <div class="videos videos-phone" v-else>
      <div class="left">
        <!-- 老师分享视频 -->
        <div class="remote-container teacher-screen" v-if="teacherScreen">
          <div
            :key="(teacherScreen.getUserId()+'screen')"
            :id="(teacherScreen.getUserId()+'screen')"
            class="remote-stream-container">
          </div>
        </div>
        <!-- 老师主视频 -->
        <div class="remote-container teacher-video" :class="teacherScreen?'teacher-video-pos':''" v-if="teacherVideo">
          <div
            :key="teacherVideo.getUserId()+'live'"
            :id="teacherVideo.getUserId()+'live'"
            class="remote-stream-container">
          </div>
        </div>

      </div>
      <div class="right">
        <div class="btn-to" @click="onBack">返回</div>
      </div>
      <div v-if="localStream" class="local-stream-container">
          <!-- 本地流播放区域 -->
          <div id="localStream" class="local-stream-content  localStream-phone" style="background:#fff"></div>
      </div>
    </div>
  </div>
</template>

<script>
import rtc from './mixins/rtc.js';
import shareRtc from  './mixins/share-rtc.js';
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';

export default {
  name: 'compRoom',
  mixins: [rtc, shareRtc],
  props: {
    room_info: Object,
    teacher_id: Number,
    type: String,
    sdkAppId: Number,
    secretKey: String,
    userId: String,
    roomId: Number,
    cameraId: String,
    microphoneId: String,
    inviteUserSig: String,
  },
  data() {
    return {
      logList: [],
      inviteLink: '',
      showCopiedTip: false,
      isPad: true,
    };
  },
  computed: {
    isHostMode() {
      return this.type !== 'invite';
    },
    isEnLang() {
      return this.$i18n.locale === 'en';
    },
    showInviteLink() {
      return this.isHostMode && (this.isJoined || this.isShareJoined) && this.inviteLink;
    },
  },
  watch: {
    cameraId(val) {
      this.switchDevice('video', val);
    },
    microphoneId(val) {
      this.switchDevice('audio', val);
    },
  },
  mounted() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    // eslint-disable-next-line no-debugger
    // debugger;
    if (width / height > 1.4) {
    //   alert('手机');
      this.isPad = false;
      this.$store.commit('updateEquipment', false);
    } else {
      this.isPad = true;
      this.$store.commit('updateEquipment', true);
    //   alert('ipad');
    }
    this.handleJoinRoom();
  },
  methods: {
    onBack() {
      this.$router.go(-1);
      this.leave();
    },
    generateInviteLink() {
      if (!this.isHostMode) {
        return;
      }
      const { sdkAppId, secretKey, roomId } = this;
      const inviteUserId = `user_${parseInt(Math.random() * 100000000, 10)}`;
      const userSigGenerator = new LibGenerateTestUserSig(sdkAppId, secretKey, 604800);
      const inviteUserSig = userSigGenerator.genTestUserSig(inviteUserId);
      this.inviteLink = encodeURI(`${location.origin}${location.pathname}#/invite?sdkAppId=${sdkAppId}&userSig=${inviteUserSig}&roomId=${roomId}&userId=${inviteUserId}`);
    },
    handleCopyInviteLink() {
      navigator.clipboard.writeText(this.inviteLink);
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
      this.generateInviteLink();
    },
    // 点击【Join Room】按钮
    async handleJoinRoom() {
      if (this.isHostMode) {
        if (!this.sdkAppId || !this.secretKey) {
          alert(this.$t('Please enter sdkAppId and secretKey'));
          return;
        }
        if (!this.userId || !this.roomId) {
          alert(this.$t('Please enter userId and roomId'));
          return;
        }
        const userSigGenerator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, 604800);
        this.userSig = userSigGenerator.genTestUserSig(this.userId);
      } else {
        if (!this.sdkAppId || !this.inviteUserSig || !this.userId || !this.roomId) {
          alert(this.$t('Please reacquire the invitation link'));
          return;
        }
        this.userSig = this.inviteUserSig;
      }
      await this.initClient();
      await this.join();
      await this.initLocalStream();
      await this.playLocalStream();
      await this.publish();
      this.generateInviteLink();
    },

    // 点击【Publish】按钮
    async handlePublish() {
      await this.publish();
    },

    // 点击【Unpublish】按钮
    async handleUnpublish() {
      await this.unPublish();
    },

    // 点击【Leave Room】按钮
    async handleLeave() {
      await this.leave();
    },

    // 点击【开始屏幕分享】按钮
    async handleStartScreenShare() {
      if (!this.sdkAppId || !this.secretKey) {
        alert(this.$t('Please enter sdkAppId and secretKey'));
        return;
      }
      await this.initShareClient();
      await this.initShareLocalStream();
      await this.handleShareJoin();
      await this.handleSharePublish();
      this.generateInviteLink();
    },

    // 点击【停止屏幕分享按钮】
    async handleStopScreenShare() {
      await this.handleShareUnpublish();
      await this.handleShareLeave();
    },

    // 显示成功的 Log
    addSuccessLog() {
      if (!this.isHostMode) {
        return;
      }
      // this.logList.push({
      //   type: 'success',
      //   log,
      // });
      // const { scrollHeight } = this.$refs.logContainer;
      // this.$refs.logContainer.scrollTop = scrollHeight;
    },

    // 显示失败的 Log
    addFailedLog() {
      if (!this.isHostMode) {
        return;
      }
      // this.logList.push({
      //   type: 'failed',
      //   log,
      // });
      // const { scrollHeight } = this.$refs.logContainer;
      // this.$refs.logContainer.scrollTop = scrollHeight;
    },
    reportSuccessEvent(name) {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },
    reportFailedEvent(name, error, type = 'rtc') {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-failed#${this.roomId}*${type === 'share' ? this.shareUserId : this.userId}*${error.message}`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#app{
  padding-bottom: 0;
}
.rtc-container {
  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }


  .invite-link-container {
    width: 100%;
    color: #084298;
    background-color: #cfe2ff;
    position: relative;
    padding: 10px 16px;
    margin-bottom: 16px;
    border: 1px solid #b6d4fe;
    border-radius: 0.25rem;
    .invite-input {
      margin-top: 10px;
    }
    .invite-btn {
      display: flex;
      cursor: pointer;
    }
  }

  .info-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .log-container {
      flex-grow: 1;
      border: 1px solid #dddddd;
      height: 360px;
      padding: 10px;
      margin-right: 16px;
      overflow-y: scroll;
      .log-label {
        margin: 0 0 6px;
        font-weight: bold;
      }
      .log-state {
        display: inline-block;
        margin-right: 6px;
      }
      > div {
        font-size: 12px;
      }
    }
    .local-stream-container {
      width: 480px;
      height: 360px;
      position: relative;
      flex-shrink: 0;
      .local-stream-content {
        width: 100%;
        height: 100%;
      }
      .local-stream-control {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;
        .control {
          margin-left: 10px;
        }
        .icon-class {
          color: #fff;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .info-container-mobile {
    display: block;
    .log-container {
      margin-right: 0;
    }
    .local-stream-container {
      width: 20vh;
      height: 15vh;
      overflow: hidden;
    border-radius: 0.4rem;
    }
  }

  .remote-container {
    width: 100%;
    height: 100%;
    // margin-top: 10px;
    // display: flex;
    // flex-wrap: wrap;
    .remote-stream-container {
      // width: 320px;
      // height: 240px;
      width: 100%;
      height: 100%;
      // margin: 0 10px 10px 0;
    }
  }
  .remain-container{
    width: 20vh;
      height: 15vh;
      overflow: hidden;
    border-radius: 0.4rem;
    margin-top: 0.2rem;
  }
}
.videos{
  // height: 60vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 25vh 0.7rem 0;
  box-sizing: border-box;
  .teacher-video{
    height: 60vh;
    width: 106vh;
    position: absolute;
    right: 0;
    bottom: 0;
    // overflow: hidden;
    // .remote-stream-container{
      // height: 60vh;
      // width: 106vh;
    // }
  }
  .teacher-video-pos{
    position: absolute;
    right: 0.7rem;
    bottom:  0.7rem;
    height: 15vh;
    width: 20vh;
    z-index: 100;
    border-radius: 0.4rem;
    overflow: hidden;
  }
  .left{
    height: 60vh;
    width: 106vh;
    position: relative;
    overflow: hidden;
    border-radius: 0.4rem;
    background: url('../assets/image/novideo.jpeg') #fff no-repeat center center;
    background-size: 15vh;
  }
}
.videos-phone{
  display: flex;
  padding: 0;
  height: 100vh;
  align-items: center;
  justify-content: center;
  .left{
    height: 85vh;
    width:151vh;
    position: relative;
    overflow: hidden;
    border-radius: 0.4rem;
    .teacher-video{
      height: 85vh;
      width:151vh;
      position: relative;
      overflow: hidden;
      border-radius: 3vh;
    }
    .teacher-video-pos{
      position: absolute;
      right: 2vh;
      bottom: 2vh;
        height: 22.5vh;
        width: 30vh;
    }
  }
  .right{
    height: 85vh;
    display: flex;
    align-items: flex-start;
    margin-left: 5vh;
  }
  .btn-to{
        border-radius: 0.1rem;
        background: #f7718a;
        color: #fff;
        text-align: center;
        padding: 0 5vh;
        height: 8vh;
        line-height:8vh;
        font-size: 3vh;
    }
}
.localStream-phone{
  position: fixed;
  top: 0;
  right: 0;
}
.top-bar{
  display: flex;
  justify-content: space-between;
  padding: 2rem 1.5rem 0;
  position: fixed;
  top: 0;
  width: 100vw;
  .teacher{
    font-size: 0.5rem;
    display: flex;
    align-items: center;
    img{
      width: 1rem;
      margin-right: 0.2rem;
    }
  }
  .class-name{
    font-size: 1rem;
    font-weight: bold;
    background-image: -webkit-linear-gradient(top,#fb8a9c,#ee5b77);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    // box-shadow: 0 0 3px #fff;
  }
  .btn-to{
        border-radius: 0.1rem;
        background: #f7718a;
        color: #fff;
        text-align: center;
        padding: 0 0.5rem;
        height: 1.1rem;
        line-height: 1.1rem;
        font-size: 0.5rem;
    }
}
</style>

<i18n>
{
	"en": {
		"Operation": "Operation",
    "Join Room": "Join Room",
    "Publish": "Publish",
    "Unpublish": "Unpublish",
    "Leave Room": "Leave Room",
    "Start Screen Share": "Start Screen Share",
    "Stop Screen Share": "Stop Screen Share",
    "Please enter sdkAppId and secretKey": "Please enter sdkAppId and secretKey",
    "Please enter userId and roomId": "Please enter userId and roomId",
    "Please reacquire the invitation link": "Please reacquire the invitation link!"
	},
	"zh": {
		"Operation": "操作",
    "Join Room": "进入房间",
    "Publish": "发布流",
    "Unpublish": "取消发布流",
    "Leave Room": "离开房间",
    "Start Screen Share": "开始共享屏幕",
    "Stop Screen Share": "停止共享屏幕",
    "Please enter sdkAppId and secretKey": "请输入 sdkAppId 和 secretKey",
    "Please enter userId and roomId": "请输入 userId 和 roomId",
    "Please reacquire the invitation link": "请重新获取邀请链接！"
	}
}
</i18n>
