<!--
 * @Description: App.vue
 * @Date: 2022-03-21 12:16:32
 * @LastEditTime: 2022-03-21 12:21:49
-->
<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.$aegis.reportEvent({
      name: 'loaded',
      ext1: 'loaded-success',
      ext2: 'webrtcQuickDemoVue2',
    });
  },
  created() {
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState({ ...this.$store.state, ...JSON.parse(sessionStorage.getItem('store')) });
    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state));
    });
  },
};
</script>
